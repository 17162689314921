import {
  FETCH_STATS_SUCCESS,
  FETCH_ABSENCE_STATS_SUCCESS
} from 'actions/types'

import { produce } from "immer";

const initialState = {
  userStats: [],
  absenceStats: []
}

const statsReducer = (state = initialState, action) => 
produce(state, draft => {
  switch (action.type) {
    case FETCH_STATS_SUCCESS:
      draft.userStats = action.payload
      break
    case FETCH_ABSENCE_STATS_SUCCESS:
      draft.absenceStats = action.payload
      break
    default: return state
  }
})
  
    
export default statsReducer   